<template>
    <v-bottom-sheet
        v-model="visible"
        persistent
    >
        <v-sheet
            class="text-center pa-5"
        >
            <v-row dense>
                <v-col cols="12">
                    <p class="text-lg-h6 error--text">An error occurred while processing your request</p>
                    <p v-if="typeof error == 'string'"><strong>{{error}}</strong></p>
                    <p v-if="error.response && error.response.data" class="text-lg-h5"><b v-if="error.response.data.type">{{error.response.data.type}}:</b> {{error.response.data.description}}</p>
                    <p class="text-lg-caption" v-if="error.config">
                        <b>Request</b>: {{error.config.method.toUpperCase()}} {{error.config.baseURL}}{{error.config.url}}
                        <span v-if="error.config.data"> - {{error.config.data}}<br/></span>
                        <b>Response</b>: {{error.response.status}} {{error.response.statusText}}<span v-if="error.response.data"> {{error.response.data}}</span>
                    </p>
                    <v-btn
                            class="mt-6"
                            text
                            block
                            color="error"
                            @click="$store.commit('closeError', index)"
                    >
                        close
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>
    </v-bottom-sheet>
</template>

<script>

    export default {
        name: 'ApplicationError',
        data: () => ({
            visible: true
        }),
        props: {
            error: {
                default: null
            },
            index: {
                default: null
            }
        }
    };
</script>