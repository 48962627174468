export default {
    mounted() {
        document.addEventListener('refreshRequested', this.refreshHandler);
    },
    beforeDestroy() {
        document.removeEventListener('refreshRequested', this.refreshHandler);
    },
    methods: {
        refreshHandler() {
            this.update();
        },
        update() {
            console.log('update requested!');
        }
    },
};