<template>
    <span v-if="!hide">
        <span v-if="label" class="font-weight-bold">{{ label }}: </span>
        <span v-html="content"></span>
    </span>
</template>

<script>
export default {
    name: 'MarkedText',
    computed: {
        escapedSearchQuery() {
            return this.markText !== null
                ? this.markText.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
                : null;
        },
        content() {
            if (this.escapedSearchQuery) {
                var reg = new RegExp(this.escapedSearchQuery, 'ig');
                return this.text ? this.text.replace(reg, '<mark>$&</mark>') : '';
            } else {
                return '';
            }
        },
        matched() {
            if (this.markText !== null) {
                if (this.noPartialMatch) {
                    return (String(this.text)===String(this.markText));
                } else {
                    var reg = new RegExp(this.escapedSearchQuery, 'ig');
                    return this.text.match(reg) !== null;
                }
            } else {
                return false;
            }
        },
        hide() {
            return (
                this.markText === null ||
                this.markText === '' ||
                (this.hideUnmatched && !this.matched)
            );
        },
    },
    props: {
        text: {
            type: String,
            default: '',
        },
        markText: {
            type: String,
            default: '',
        },
        noPartialMatch: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hideUnmatched: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped></style>
