import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import { Ripple } from 'vuetify/lib/directives';
import '@mdi/font/css/materialdesignicons.css';

// import custom icons
import { mdiAlphabeticalVariant, mdiHandshake, mdiNumeric } from '@mdi/js';


Vue.use(Vuetify, {
    directives: {
        Ripple,
    },
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            partner: mdiHandshake,
            letters: mdiAlphabeticalVariant,
            numbers: mdiNumeric
        }
    },
    theme: {
        themes: {
            light: {
                primary: '1583c2', // BreezeConnect Blue
                secondary: 'f89f3b', // BreezeConnect Orange
                accent: '38a4de', // Lightened BC Blue
                background_accent: 'eeeeee', // light grey
                breeze_orange: 'f89f3b', // BreezeConnect Orange
                breeze_blue: '1583c2', // BreezeConnect Blue
            },
            dark: {
                primary: 'f89f3b', // BreezeConnect Orange
                secondary: '1583c2', // BreezeConnect Blue
                accent: 'b36a11', // Darkened BC orange
                background_accent: '2f2f2f', // dark grey
                breeze_orange: 'f89f3b', // BreezeConnect Orange
                breeze_blue: '1583c2', // BreezeConnect Blue
            },
        },
        options: {
            minifyTheme: function (css) {
                return process.env.NODE_ENV === 'production'
                    ? css.replace(/[\r\n|\r|\n]/g, '')
                    : css
                },

        },
    },
});
