<template>
    <v-fade-transition hide-on-leave>
        <v-img
            :src="require('@/assets/img/logo' + (white ? '-white' : '') + '.png')"
            alt="Breeze Connect Logo"
            contain
            eager
            :max-height="maxHeight"
            :max-width="maxWidth"
            :height="height"
            :width="width"
        />
    </v-fade-transition>
</template>

<script>
export default {
    name: 'BreezeLogo',
    props: {
        app: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: [String, Number],
            default: null,
        },
        maxWidth: {
            type: [String, Number],
            default: null,
        },
        height: {
            type: [String, Number],
            default: null,
        },
        width: {
            type: [String, Number],
            default: null,
        },
        white: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
