<template>
    <div>
        <v-tooltip bottom open-delay="200">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    v-on:click="$vuetify.theme.dark = !$vuetify.theme.dark"
                    icon
                >
                    <v-icon :color="color" v-html="$vuetify.theme.dark ? 'mdi-brightness-5' : 'mdi-brightness-3'" />
                </v-btn>
            </template>
            {{tooltip}}
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'ThemeSwitcher',
    computed: {
        tooltip() {
            return 'Switch to ' + (this.$vuetify.theme.dark ? 'light' : 'dark') + ' theme';
        }
    },
    props: {
        color: {
            type: String,
            default: 'white'
        }
    }
};
</script>
