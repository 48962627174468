<template>
    <v-slide-y-transition>
        <v-banner
          two-line
            v-show="visible"
            class="elevation-7"
            style="z-index: 5; border-left-width: 6px; border-left-style: solid;"
            :style="{'border-color':classMap[colorTag]['accent-default'][$vuetify.theme.dark?'dark':'light']+' !important'}"
            :color="classMap[colorTag].background.color"
            :class="classMap[colorTag].background[$vuetify.theme.dark?'dark':'light']"
        >
            <v-icon :color="classMap[colorTag].icon[$vuetify.theme.dark?'dark':'light']" size="36" class="pr-3">
                {{ classMap[colorTag].icon.icon }}
            </v-icon>
            {{ message }}
            <template v-slot:actions>
                <v-btn :color="classMap[colorTag].accept[$vuetify.theme.dark?'dark':'light']" text @click="acceptBanner">
                    {{ accept }}
                </v-btn>
              <v-btn text @click="dismissBanner">
                {{ dismiss }}
              </v-btn>
            </template>
        </v-banner>
    </v-slide-y-transition>
</template>
<script>
import { color } from "chart.js/helpers";

export default {
    name: 'Banner',
    data: () => ({
        visible: false,
        classMap:{
            "info":{
              "background":{
                "color":null,
                "light":"blue lighten-5",
                "dark":"blue darken-4"
              },
              "icon":{
                "icon":"mdi-information",
                "light":"warning",
                "dark":"warning",
              },
              "accept":{
                "light":"warning",
                "dark":"warning"
              },
              "accent-default":{
                // "light":"#0077d2",
                // "dark":"#0077d2",
                "light":"#fb8c00",
                "dark":"#fb8c00"
              },
            },
           "error":{
             "background":{
               "color":null,
               "light":"red lighten-4",
               "dark":"red darken-4"
             },
             "icon":{
               "icon":"mdi-alert-circle",
               "light":"red",
               "dark":null,
             },
             "accept":{
               "light":"red",
               "dark":null
             },
             "accent-default":{
               "light":"red",
               "dark":"red"
             },
           },
          "warning":{
            "background":{
              "color":null,
              "light":"amber lighten-4",
              "dark":"warning"
            },
            "icon":{
              "icon":"mdi-alert",
              "light":"warning",
              "dark":null,
            },
            "accept":{
              "light":"warning",
              "dark":null
            },
            "accent-default":{
              "light":"#fb8c00",
              "dark":"#fb8c00"
            },
          },
          "success":{
            "background":{
              "color":null,
              "light":"green lighten-4",
              "dark":"success darken-1"
            },
            "icon":{
              "icon":"mdi-check-circle",
              "light":"success",
              "dark":null,
            },
            "accept":{
              "light":"success",
              "dark":null
            },
            "accent-default":{
              "light":"#4caf50",
              "dark":"#4caf50"
            },
          }
       }
    }),
    watch: {
        show: function (value) {
            this.visible = value;
        },
    },
    methods: {
        dismissBanner: function () {
            this.$emit('dismiss');
        },
        acceptBanner: function () {
            this.$emit('accept');
        },
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        colorTag: {
            type: String,
            default: 'success',
        },
        message: {
            type: String,
            default: '',
        },
        accept: {
            type: String,
            default: 'Accept',
        },
        dismiss: {
            type: String,
            default: 'Dismiss',
        },
        show: {
            type: [Boolean, String],
            default: false,
        },
        bannerPosition: {
          type: Number,
          default: 1,
        },
    },
    created() {
        this.visible = this.show;
        if(this.icon != null){
          this.classMap[this.colorTag].icon.icon = this.icon;
        }
    },
};
</script>
