import apiMixin from './apiMixin';
import refreshListenerMixin from './refreshListenerMixin';
export default {
    mixins: [apiMixin, refreshListenerMixin],
    computed: {
        partnerLoading() {
            return this.$store.state.loading.partner;
        },
        partnerData() {
            if (
                this.$store.state.data.partner &&
                (this.$store.state.data.partner.i_rep === parseInt(this.$route.params.partnerId) ||
                this.$store.state.user.user_type === 'partner')
            ) {
                return this.$store.state.data.partner;
            }
            return {};
        },
        partnerId() {
            return parseInt(this.$route.params.partnerId);
        },
    },

    methods: {
        async get(id, force) {
            if (
                force ||
                !this.$store.state.data.partner ||
                this.$store.state.data.partner.i_rep !== parseInt(id)
            ) {
                this.$store.commit('loading', { name: 'partner', data: true });
                const response = await this.Api.send('post',
                    id ? 'partners/' + id + '/info' : 'partner/info'
                );
                if (response.success) {
                    this.$store.commit('data', {
                        name: 'partner',
                        data: response.data,
                    });
                } else {
                    this.updateFailed(response.error, response.errorDetail);
                }
                this.$store.commit('loading', { name: 'partner', data: false });
            }
        },
        refresh() {
            this.get(this.$route.params.partnerId, true);
        },
        //uses the refreshListenerMixin to update the page when the refresh icon is selected
        update() {
            this.get(this.$route.params.partnerId, true);
        },
        updateData(data) {
            this.data = data;
        },
        updateFailed() {
            this.data = [];
        },
    },
    //Moved to the PartnerParent component. This was being called too many times.
    // created() {
    //     this.get(this.$route.params.partnerId);
    // },

};