<template>
    <v-footer app class="pa-4 warning" style="z-index: 5;">
        <v-row style="flex-wrap: nowrap;" align="center" >
            <v-col class="flex-grow-0 flex-shrink-0">
                <v-icon v-html="'mdi-information-outline'" right />
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-0">
                An updated version of the app is available.
            </v-col>
            <v-col class="flex-grow-0 flex-shrink-0">
                <v-btn @click="update" :loading="refreshClicked">Update Now</v-btn>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: 'ApplicationUpdateFooter',
    data: () => ({
        refreshClicked: false
    }),
    methods: {
        update() {
            this.refreshClicked = true;
            this.$emit('update');
        },
    },
};
</script>
