import axios from 'axios';
import router from '../router';
import store from '../store';

//import Vue from 'vue';

export default class Api {
    constructor() {
        this.setHttpObject();
    }

    getRequestClient( ){
        return this.http;
    }

    /**
     * Process to build the axios object that is to be used to make API calls
     * @param config object containing various config settings for the axios object.
     * Allows the ability to adjust the settings of the axios object.
     * eg
     * {
     *     "timeout":300000,
     *     "headers": {
     *          "Content-Type": "multipart/form-data"
     *      }
     * }
     *
     * NOTE after each call the http object is reset to its default configuration.
     *
     * @returns {AxiosInstance}
     */
    buildHttpObject(config)
    {
        //this toggle helps with process to reset to default
        this.defaultConfig = true;
        if(config != undefined){
            this.defaultConfig = false;
        }

        if(!config){
            config = {};
        }
        //Not sure if this will ever need overwriting. As its tightly coupled with the Auth process.
        if(!config.baseURL){
            config.baseURL = process.env.VUE_APP_API_BASE || window.location.origin + '/api/';
        }
        if(!config.timeout){
            config.timeout = 20000;
        }

        if(!config.headers){
            config.headers = {};
        }
        config.headers.Authorization = `Bearer ${localStorage.token}`;

        let http = axios.create(config);

        // Add a response interceptor
        http.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                // Do something with response error
                return Promise.reject(error);
            }
        );

        return http;
    }

    /**
     * This method allow us to reset the axios object.
     * Helps in us to set various setting and continues to use existing framework infrastructure
     */
    setHttpObject(config){
        this.http = this.buildHttpObject(config);
    }

    send(method, path, data, suppressError = false) {
        const _this = this;
        return this.http
            .request({
                method: method,
                url: path,
                data: data
            })
            .then((response) => {
                if (typeof response.data != 'object' && response.status != 204) {
                    try {
                        let test = JSON.parse(response.data);
                    } catch (err) {
                        if (!suppressError) this.displayError( 'API response is not valid JSON. Error:' + err,path,response.status)
                        return {
                            success: false,
                            data: null,
                            status: null,
                            error: 'API response is not valid JSON. Error:' + err,
                            errorDetail:
                                'API response is not valid JSON. Error:' + err,
                        };
                    }
                }
                return {
                    success: true,
                    redirect: false,
                    status: response.status,
                    data: response.data,
                };
            })
            .catch((error) => {
                return this.catchReturn(error,path,suppressError);
            })
            .finally(function () {
                if(!_this.defaultConfig){
                    //reset to default
                    _this.setHttpObject();
                }
                return {
                    success: false,
                    data: null,
                    status: null,
                    error: 'Unknown',
                    errorDetail: 'An unknown error occurred',
                };
            });
    }

    displayError(error,path,responseStatus){
        if ((!path || path.split('/')[0] !== 'auth') && responseStatus !== 401) store.commit('showError', error);
    }
    async catchReturn(error,path,suppressError){
        if (error.response) {
            if (
                error.response.status === 401 &&
                path.split('/')[0] !== 'auth'
            ) {
                store.commit('logout');
                let params = { error: 'Session expired' };
                if (process.env.VUE_APP_DEBUG === 'true'){
                    params.error = 'Session expired. DEBUG: '+error.response.data.type+' - '+error.response.data.description;
                }
                router.push({
                    name: 'Login',
                    params: params,
                    query:
                        router.currentRoute.name !== 'Login'
                            ? { redirect: router.currentRoute.path }
                            : {},
                });
            }
            if (!suppressError) this.displayError(error,path,error.response.status)
            return {
                success: false,
                redirect: error.response.status === 401,
                status: error.response.status,
                data: error.response.data,
                error: error.response.data.type,
                errorDetail: error.response.data.description,
            };
        } else {

            /**
             * Note that is a response is never received then we do not get any useful information.
             * This can happen when a timeout or even with a 503 error.
             * Even thought the OPTIONS preflight is failing with a 503 error we get nothing here.
             * This is the best source of information I can find on the problem
             * https://github.com/axios/axios/issues/960
             * But there is no solution. Not even anything useful official documentation.
             *
             * So another call is made here to check if we are in maintenance mode.
             */

            // console.log(error);
            // console.log(error.request);
            // console.log(path);
            // console.log(suppressError);
            // console.log(error.toJSON());
            // let errorObject=JSON.parse(JSON.stringify(error));
            // console.log(errorObject);
            //dispatch(authError(errorObject.response.data.error));

            let maintenance = await this.checkForMaintenanceMode( );
            if(!maintenance.success || maintenance.success === false || !maintenance.data || maintenance.data.maintenance_mode != true){
                return {
                    success: false,
                    data: null,
                    status: null,
                    error: 'Timeout',
                    errorDetail:
                        'Unable to contact the server or timeout. Please try again later or narrow down your search criteria. ' + error,
                };
            }else{
                let ret = {
                    success: false,
                    data: null,
                    status: 503,
                    error: 'Service Unavailable',
                    errorDetail:
                        'Breeze Connect is down for maintenance. Please try again later.'
                };
                if(path != 'auth/login'){
                    /**
                     * We need to display the error so that people within the App will see it.
                     * Because many failed returns are not handled.
                     */
                    this.displayError(ret.errorDetail,path,503);
                }
                return ret;
            }
        }
    }

    async checkForMaintenanceMode( ){
        const _this = this;
        return this.http
            .request({
                method: 'get',
                url: 'maintenance',
                data: {}
            })
            .then((response) => {
                if (typeof response.data != 'object' && response.status != 204) {
                    return {
                        success: true
                    }
                }
                return {
                    success: true,
                    data: response.data,
                };
            })
            .catch((error) => {
                return {
                    success: true
                }
            })
            .finally(function () {
                return {
                    success: true
                }
            });
    }

    /**
     * this allows us to manually throw error in similar format to an API error.
     * @param message
     */
    throwError(message)
    {
        store.commit('showError', message);
    }

}
