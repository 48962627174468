export default {
    data: function() {
        return {
           // snackbarObjects:[],
            //showSuccessSnackBar:false,
        }
    },
    methods: {
        /**
         * @returns {boolean}
         * Return if this code is running on a server or not.
         * The production toggle is missleading.
         * It does not represent if PortaOne is production.
         * Also this is set to 'production' in the staging environment.
         * The current understanding is this toggle depicts if the code is
         * deployed on a server or not.
         */
        isProduction( ){
            return process.env.NODE_ENV === 'production';
        },
        isPortaStaging( ){
            const url = new URL(window.location.href);
            const hostname = url.hostname; // Extract the hostname from the URL
            const parts = hostname.split('.'); // Split the hostname by the dots
            let subdomain = parts[0]; // Assume the subdomain is the first part

            if(subdomain == 'staging' || subdomain == 'staging2'){
                return true;
            }else if(subdomain == 'portal' || subdomain == 'beta'){
                return false;
            }

            if(!this.isProduction( )){
                //we assume we are in a testing env. Like localhost
                return true;
            }

            console.error("Unable to determine staging env for domain ("+subdomain+")");
            return true; //return as staging to be safer.

        },
        showGlobalSuccessMessage(message){
            this.showSnackbarMessage('success',message);
        },
        showGlobalErrorMessage(message){
            this.showSnackbarMessage('error',message);
        },

        showSnackbarMessage(type, message){
          const validTypes = [
              'success',
              'error',
              'warning'
          ];

          if(!validTypes.includes(type)){
              console.error("Invalid message type of ("+type+").");
              throw "Invalid message type of ("+type+").";
          }
            this.$store.commit('data', {
                name: 'snackbarObjectStore',
                data: {
                    message:type+':'+message,
                    color:type,
                    timeout:5000
                    //timeout: -1 //no timeout
                }
            });

        },
    },
    filters: {
        currency: function(value){
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'AUD',
                currencyDisplay:'narrowSymbol',
            });
            return formatter.format(value);
        },
        thousandSeparator: function(value) {
            if (!value) value = 0;

            let decimal = false;
            if(value % 1 != 0){
                decimal = true;
            }

            let ret = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            if(!decimal){
                ret = ret+'.00';
            }
            return ret;
        },
    }

};