<template>
    <SimpleDialogOld
        title="Update Available"
        :visible="visible"
        @click="$emit('update')"
        @close="$emit('cancel')"
        persistent
    >
        An update for the <span v-html="$store.state.app.standalone ? 'app' : 'portal'" /> is available. Would you like to update now?<br/>
        Any unsaved changes will be lost.
    </SimpleDialogOld>
</template>

<script>
    import SimpleDialogOld from '../../dialogs/templates/SimpleDialogOld';
    export default {
        name: 'ApplicationUpdateDialog',
        components: { SimpleDialogOld },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
        },
    };
</script>

<style scoped>

</style>