<template>
    <v-navigation-drawer
        :app="!hide || $vuetify.breakpoint.smAndDown"
        :style="{
            'max-height':
                $vuetify.breakpoint.mdAndUp && hide ? height + 'px' : false,
        }"
        clipped
        :key="hide + show"
        :permanent="!hide"
        :temporary="hide"
        :touchless="!allowTouch"
        v-model="show"
        class="elevation-2"
    >
        <!-- div v-if="hide && $vuetify.breakpoint.mdAndUp" style="height: 8px;"/ -->
        <!-- Breeze Logo (mobile view only) -->
        <div v-if="mainMenu && hide" class="d-flex align-center justify-center" :style="'height: ' + ($vuetify.breakpoint.mdAndUp ? 64 : 56) + 'px;'">
            <BreezeLogo :height="32" :width="180" />
        </div>
        <v-divider v-if="mainMenu && hide" />

        <v-list v-if="hide && !mainMenu" class="py-0">
            <v-list-item @click="$emit('menu')">
                <v-list-item-action>
                    <v-icon v-html="'mdi-chevron-left'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Main Menu</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
        </v-list>

        <slot />
        <!-- Bottom of Navigation Drawer -->
        <template v-slot:append v-if="$slots.bottom">
            <v-divider />
            <slot name="bottom" v-if="$slots.bottom" />
        </template>
    </v-navigation-drawer>
</template>

<script>
import BreezeLogo from '../../pieces/Public/BreezeLogo';
export default {
    name: 'NavMenu',
    components: { BreezeLogo },
    data: () => ({
        show: false,
        appVersion: process.env.VUE_APP_VERSION,
        height: window.innerHeight,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        mainMenu: {
            type: Boolean,
            default: false,
        },
        allowTouch: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show: function (val) {
            this.height = window.innerHeight;
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    created() {
        this.show = this.value;
    },
};
</script>

<style scoped>
    .v-navigation-drawer--temporary.v-navigation-drawer--clipped {
            /*z-index: 5;
            padding-top: 56px;*/
            position: fixed;
        }
</style>