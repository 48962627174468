<template>
    <v-footer color="red" class="white--text" app>
        <span class="white--text caption">
            WARNING: This is a development copy of the portal and is for testing
            purposes only.
        </span>
    </v-footer>
</template>

<script>
export default {
    name: 'ApplicationFooter',
    data: () => ({}),
};
</script>
