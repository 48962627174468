<template>
    <v-overlay v-bind:class="{ 'v-overlay--absolute': absolute }" :opacity="opacity" :color="overlayColor">
        <v-row class="text-center">
            <v-col cols="12">
                <v-progress-circular
                    v-if="!hideSpinner"
                    :size="70"
                    :width="7"
                    :color="spinnerColor"
                    indeterminate
                ></v-progress-circular>
            </v-col>
            <v-col
                cols="12"
                v-if="message"
                class="text-h6"
                :style="'color: ' + spinnerColor + ';'"
            >
                {{ message }}
            </v-col>
        </v-row>
    </v-overlay>
</template>

<script>
export default {
    name: 'SpinnerOverlay',
    computed: {
        spinnerColor() {
            return this.color
                ? this.color
                : '#' +
                      (this.$vuetify.theme.isDark
                          ? this.$vuetify.theme.themes.dark.primary
                          : this.$vuetify.theme.themes.light.primary);
        },
        overlayColor() {
            return this.opacity>=1 ? (this.$vuetify.theme.isDark ? 'black' : 'white') : undefined;
        }
    },
    props: {
        hideSpinner: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        message: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: null,
        },
        opacity: {
            type: Number,
            default: undefined,
        },
    },
};
</script>
