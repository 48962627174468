// set title loading
document.title = "Loading - Breeze Connect";

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import VuePapaParse from 'vue-papa-parse';
import VueLuxon from 'vue-luxon';
import vuetify from './plugins/vuetify';
import vClipboard from 'v-clipboard';
import GlobalFetchStoreMixin from './mixins/GlobalFetchStoreMixin';
import GlobalHelperMixin from "./mixins/GlobalHelperMixin";

const debug = process.env.VUE_APP_DEBUG === 'true';
Vue.config.productionTip = debug;
Vue.config.devtools = debug;

Vue.prototype.$log = console.log;

//TODO: put google autocomplete into component
// fix for vuetify text not loaded for google autocomplete
import { VTextField } from 'vuetify/lib';

Vue.component('v-text-field', VTextField);
Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: process.env.VUE_APP_GOOGLE_AUTOCOMPLETE_APIKEY,
});
Vue.use(VuePapaParse);
Vue.use(VueLuxon, {
    input: { format: 'sql', zone: 'utc' },
});

// add v-clipboard
Vue.use(vClipboard);

Vue.mixin(GlobalFetchStoreMixin);
Vue.mixin(GlobalHelperMixin);

// global css
import '@/assets/global.css';
import './registerServiceWorker';

new Vue({
    router,
    store,
    vuetify,
    beforeCreate() {
        //this.$store.commit('init');
    },
    render: (h) => h(App),
}).$mount('#app');
