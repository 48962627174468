<template>
    <v-dialog v-model="show" :persistent="persistent" :max-width="maxWidth">
        <v-card :loading="loading" :disabled="loading">
            <v-card-title v-if="title">{{ title }}</v-card-title>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('close')"
                    ><span v-if="okOnly">OK</span
                    ><span v-if="!okOnly">{{ noText }}</span></v-btn
                >
                <v-btn
                    v-if="!okOnly"
                    :color="yesColor"
                    text
                    @click="$emit('click')"
                    :disabled="!yesEnabled"
                    >{{ yesText }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'SimpleDialogOld',
    data() {
        return {
            show: false,
        };
    },
    watch: {
        visible: function (val) {
            this.show = val;
        },
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '500',
        },
        yesColor: {
            type: String,
            default: 'primary',
        },
        okOnly: {
            type: Boolean,
            default: false,
        },
        noText: {
            type: String,
            default: 'No',
        },
        yesText: {
            type: String,
            default: 'Yes',
        },
        yesEnabled: {
            default: true,
        },
    },
    created() {
        this.show = this.visible;
    },
};
</script>
